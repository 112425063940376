import { GetServerSideProps } from 'next';
import { getSession } from 'next-auth/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

const AuthenticationContainerDynamic = dynamic(
  () => import('../../containers/authentication/authentication.container'),
  {
    ssr: false,
  },
);

const AuthenticationLayoutNewDynamic = dynamic(
  () => import('../../containers/authentication-layout/authentication-layout.container'),
  {
    ssr: false,
  },
);

const AuthenticationPage = (): ReactElement => {
  const { t } = useTranslation('auth');

  return (
    <>
      <Head>
        <title>{t('authentication')}</title>
      </Head>
      <AuthenticationContainerDynamic />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req } = context;
  if (locale === undefined) {
    throw new Error('locale is missing');
  }
  const session = await getSession({ req });
  return {
    props: {
      ...(await serverSideTranslations(locale, ['auth'])),
      session,
    },
  };
};

AuthenticationPage.getLayout = function getLayout(page: ReactElement) {
  return <AuthenticationLayoutNewDynamic>{page}</AuthenticationLayoutNewDynamic>;
};

export default AuthenticationPage;
